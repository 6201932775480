import { useState } from 'react';
import Badge from '@/components/ui/Badge';
import { Coins } from '@/components/ui/Credits';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { ROUTES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { useCreditsStore } from '@/store';
import { cn } from '@/utils';
export function CreditsModal({
  variant = 'light',
  className,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const t = useTranslations();
  const {
    data: session
  } = useSession();
  const {
    credits
  } = useCreditsStore();
  const classes = cn('credit-balance', className);
  return <>
      <span className="cursor-pointer" onClick={() => setOpen(true)}>
        <Badge variant={variant} className={classes} value={<Coins value={credits} />} {...props} data-sentry-element="Badge" data-sentry-source-file="CreditsModal.jsx" />
      </span>

      <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-source-file="CreditsModal.jsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="CreditsModal.jsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="CreditsModal.jsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="CreditsModal.jsx">{t('payment.common.credit_balance')}</DialogTitle>
          </DialogHeader>

          <p className="mb-4">{t('tutorials.credits.text')}</p>
          <p className="mb-4">{t('tutorials.credits.how_to')}</p>

          <div className="grid gap-3 mt-4 md:grid-cols-2">
            <Badge href={ROUTES.PLAYLISTS} icon="ListMusic" variant="primary-light" className="w-full p-4 text-lg hover:opacity-70 hover:scale-95" label={t('navigation.playlists')} onClick={() => setOpen(false)} data-sentry-element="Badge" data-sentry-source-file="CreditsModal.jsx" />

            <Badge href={ROUTES.TRACKS} icon="Play" variant="gold-light" className="w-full p-4 text-lg hover:opacity-70 hover:scale-95" label={t('navigation.vip_tracks')} onClick={() => setOpen(false)} data-sentry-element="Badge" data-sentry-source-file="CreditsModal.jsx" />
          </div>
        </DialogContent>
      </Dialog>
    </>;
}
export default CreditsModal;