import React from 'react';
import Select, { components } from 'react-select';
import Icon from '@/components/ui/Icon';
import { LANGUAGES } from "@/data/lang";
import { useLocale, useTranslations } from '@/hooks';
import { usePathname, useRouter } from '@/i18n/routing';
import { cn } from '@/utils';
export default function LanguageDropdown({
  icon,
  fullLabel = false,
  className
}) {
  const router = useRouter();
  const pathname = usePathname();
  const {
    locale
  } = useLocale();
  const t = useTranslations();
  const languageOptions = LANGUAGES.map(language => ({
    value: language.value,
    label: fullLabel ? t(`languages.${language.value}`) : language.value.toUpperCase(),
    icon
  }));
  const handleLanguageChange = _locale => {
    router.replace(pathname, {
      locale: _locale.value
    });
  };
  const CustomOption = ({
    children,
    ...props
  }) => {
    return <components.Option {...props} data-sentry-element="unknown" data-sentry-component="CustomOption" data-sentry-source-file="LanguageDropdown.jsx">
        <div className="flex items-center gap-2">
          <span className="inline-flex justify-center items-center px-2 py-1 text-xs w-[30px] font-medium tracking-wide uppercase rounded-sm border-outline">
            {props.data.value}
          </span>
          <span>{props.data.label}</span>
        </div>
      </components.Option>;
  };
  const CustomSingleValue = ({
    children,
    ...props
  }) => {
    return <components.SingleValue {...props} data-sentry-element="unknown" data-sentry-component="CustomSingleValue" data-sentry-source-file="LanguageDropdown.jsx">
        <div className="flex items-center gap-2">
          {icon && <Icon name={icon} className="w-4 h-4" />}
          <span className="font-medium">{children}</span>
        </div>
      </components.SingleValue>;
  };
  return <Select value={languageOptions.find(({
    value
  }) => value === locale)} options={languageOptions} onChange={handleLanguageChange} className={cn('select language-dropdown', className)} classNamePrefix="select" isSearchable={false} components={{
    Option: CustomOption,
    SingleValue: CustomSingleValue
  }} data-sentry-element="Select" data-sentry-component="LanguageDropdown" data-sentry-source-file="LanguageDropdown.jsx" />;
}