'use client';

import { useEffect } from 'react';
import { CountdownDate } from '@/components/ui/Countdown';
import Icon from '@/components/ui/Icon';
import { DEFAULT_LANGUAGE } from '@/data';
import { useDimensions, useLocale, useTranslations } from '@/hooks';
import { useBannerStore } from '@/store';
import { cn } from '@/utils';
export function BannerNotice({
  id,
  variant = 'primary',
  bodyClass = 'has-notice',
  size = 'full',
  floating = false,
  banner,
  children,
  className
}) {
  const {
    dismissed,
    dismissBanner
  } = useBannerStore();
  const [ref, {
    height
  }] = useDimensions({});
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  useEffect(() => {
    const isVisible = banner || children;
    const shouldShow = !id || id && !dismissed.includes(id);
    if (isVisible && shouldShow) {
      document.body.classList.add(bodyClass);
    } else if (!floating) {
      document.body.classList.remove(bodyClass);
    }
    return () => {
      if (!floating) {
        document.body.classList.remove(bodyClass);
      }
    };
  }, [banner, children, id, dismissed]);
  useEffect(() => {
    if (height) {
      const noticeId = id || 'notice';
      document.body.style.setProperty(`--${noticeId}-height`, `${height}px`);
    }
  }, [height]);
  const handleDismiss = () => {
    if (id) dismissBanner(id);
  };
  const classes = cn({
    'banner-notice': true,
    [`banner-notice-${size}`]: size,
    [`banner-${variant}`]: variant,
    dismissed: dismissed.includes(id),
    'text-center': true,
    'pr-8': banner?.dismissable,
    [className]: className
  });
  const getBannerText = () => {
    if (banner?.text?.[locale]) return banner.text[locale];
    return banner.text[DEFAULT_LANGUAGE];
  };
  const bannerCtaLabel = () => {
    if (banner?.cta?.label?.[locale]) return banner.cta.label[locale];
    return banner.cta.label[DEFAULT_LANGUAGE];
  };
  if (!banner && !children) return null;
  return <div id={id} ref={ref} className={classes} data-sentry-component="BannerNotice" data-sentry-source-file="BannerNotice.jsx">
      {banner?.text && <span className="inline-flex flex-wrap items-center gap-2">
          {banner?.icon && <Icon name={banner.icon} />}

          <p>{getBannerText()}</p>

          {banner?.cta && <a href={banner?.cta?.url} className="font-semibold underline" target={banner?.cta?.external ? '_blank' : '_self'} rel="noreferrer">
              {bannerCtaLabel()}
            </a>}

          {banner?.countdown && banner.endDate && <CountdownDate inline endDate={banner.endDate} className="ml-2" />}
        </span>}

      {!banner && children ? children : null}

      {banner?.dismissable && <button onClick={handleDismiss} className="absolute right-2 top-[50%] -translate-y-1/2">
          <Icon name="X" />
        </button>}
    </div>;
}
export default BannerNotice;