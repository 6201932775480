'use client';

import { signOut } from "next-auth/react";
import { useState } from 'react';
import NavProfileDropdown from './NavProfileDropdown';
import { matchfyApi } from '@/api';
import { Development, DevRefreshUserCache } from '@/components/Development';
import Avatar from '@/components/ui/Avatar';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/Dropdown/DropdownMenu';
import ThemeToggle from '@/components/ui/Header/ThemeToggle';
import Icon from '@/components/ui/Icon';
import LanguageDropdown from '@/components/ui/LanguageDropdown';
import { LOGOUT_REDIRECT, PLANS, PROFILE_NAV, ROUTES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
import { clearStorage } from '@/utils';
function NavProfile({
  showPlanBadge = false
}) {
  const t = useTranslations();
  const [open, setOpen] = useState(false);
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const logout = async () => {
    matchfyApi.logout();
    clearStorage();
    signOut({
      callbackUrl: LOGOUT_REDIRECT
    });
  };
  const handleItemClick = item => {
    setOpen(false);
  };
  const SignoutButton = () => {
    return <Button variant="link-plain" label={t('navigation.logout')} icon="LogOut" className="justify-start px-3 py-2 text-red h-[40px]" onClick={logout} data-sentry-element="Button" data-sentry-component="SignoutButton" data-sentry-source-file="NavProfile.jsx" />;
  };
  if (!session) {
    return null;
  }
  const dropdownLinkClasses = 'flex items-center justify-start w-full gap-2 h-[40px] p-3 hoverable';
  return <DropdownMenu open={open} onOpenChange={setOpen} className="z-[1000]" data-sentry-element="DropdownMenu" data-sentry-component="NavProfile" data-sentry-source-file="NavProfile.jsx">
      <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="NavProfile.jsx">
        <div className="flex items-center px-3 py-2 rounded cursor-pointer nav-user hoverable">
          <Avatar size="sm" src={user.avatar} label={user.spotifyId} star={user?.star || false} data-sentry-element="Avatar" data-sentry-source-file="NavProfile.jsx" />

          <div className="flex flex-wrap items-center justify-between w-full gap-1 ml-2 overflow-hidden">
            <span className="hidden text-sm font-medium truncate md:block">
              {user?.spotifyUsername ?? user?.spotifyId}
            </span>

            {user?.plan?.name && showPlanBadge && <Badge variant="primary-light" className="text-[0.7rem] py-1 px-2 capitalize" label={user?.plan?.name} />}
          </div>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="w-[200px] overflow-visible" data-sentry-element="DropdownMenuContent" data-sentry-source-file="NavProfile.jsx">
        <NavProfileDropdown user={user} data-sentry-element="NavProfileDropdown" data-sentry-source-file="NavProfile.jsx" />

        <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="NavProfile.jsx" />

        {user?.plan?.name === PLANS.FREE && <>
            <DropdownMenuItem asChild className="p-0">
              <Link href={ROUTES.PRICING} className={dropdownLinkClasses}>
                <Icon name="Sparkles" className="w-4 h-4" />
                Upgrade
              </Link>
            </DropdownMenuItem>

            <DropdownMenuSeparator />
          </>}

        {PROFILE_NAV.map(item => <DropdownMenuItem asChild key={item.path} className="p-0 rounded-sm">
            <Link href={item.path} className={dropdownLinkClasses} onClick={() => handleItemClick(item)}>
              {item.icon && <Icon name={item.icon} className="w-4 h-4" />}
              {t(item.title)}
            </Link>
          </DropdownMenuItem>)}

        <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="NavProfile.jsx" />

        <LanguageDropdown fullLabel icon="Languages" className="justify-start w-full text-[.8rem]" data-sentry-element="LanguageDropdown" data-sentry-source-file="NavProfile.jsx" />

        <ThemeToggle showLabel className="font-normal justify-start w-full px-3 text-[.8rem] rounded-sm" data-sentry-element="ThemeToggle" data-sentry-source-file="NavProfile.jsx" />

        <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="NavProfile.jsx" />

        <Development data-sentry-element="Development" data-sentry-source-file="NavProfile.jsx">
          <DevRefreshUserCache data-sentry-element="DevRefreshUserCache" data-sentry-source-file="NavProfile.jsx" />
          <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="NavProfile.jsx" />
        </Development>

        <DropdownMenuItem className="p-0" data-sentry-element="DropdownMenuItem" data-sentry-source-file="NavProfile.jsx">
          <SignoutButton data-sentry-element="SignoutButton" data-sentry-source-file="NavProfile.jsx" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>;
}
export default NavProfile;