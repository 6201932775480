import { ChevronRight } from 'lucide-react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/Collapsible/Collapsible';
import Icon from '@/components/ui/Icon';
import { SidebarGroup, SidebarGroupContent, SidebarGroupLabel, SidebarMenu, SidebarMenuButton, SidebarMenuItem } from '@/components/ui/Sidebar/Sidebar';
import { useActiveRoute, useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
import { usePreferencesStore } from '@/store';
import { cn } from '@/utils';
export function SidebarPrimaryNav({
  items,
  onItemClick
}) {
  const t = useTranslations();
  const {
    openedItems,
    closedItems,
    openItem,
    closeItem,
    getOpenState
  } = usePreferencesStore();
  const {
    isActive
  } = useActiveRoute();
  const handleOpenChange = (open, groupId) => {
    if (groupId) {
      open ? openItem(groupId) : closeItem(groupId);
    }
  };
  const handleItemClick = item => {
    if (onItemClick) {
      onItemClick(item);
    }
  };
  const renderMenuItem = item => {
    return <SidebarMenuItem key={item.path} id={item.id ? `nav-${item.id}` : undefined} data-sentry-element="SidebarMenuItem" data-sentry-component="renderMenuItem" data-sentry-source-file="SidebarPrimaryNav.jsx">
        <SidebarMenuButton asChild className={cn({
        active: isActive(item.path)
      })} tooltip={t(item.title)} data-sentry-element="SidebarMenuButton" data-sentry-source-file="SidebarPrimaryNav.jsx">
          <Link href={item.path} onClick={() => handleItemClick(item)} data-sentry-element="Link" data-sentry-source-file="SidebarPrimaryNav.jsx">
            {item.icon && <Icon name={item.icon} type={item?.iconType} />}
            <span>{t(item.title)}</span>
          </Link>
        </SidebarMenuButton>
      </SidebarMenuItem>;
  };
  return <SidebarGroup data-sentry-element="SidebarGroup" data-sentry-component="SidebarPrimaryNav" data-sentry-source-file="SidebarPrimaryNav.jsx">
      {items && items?.length > 0 && items.map((group, index) => {
      if (group?.groupTitle) {
        return <Collapsible key={group.groupTitle} open={getOpenState(group?.groupId)} className="group/collapsible" onOpenChange={open => handleOpenChange(open, group?.groupId)}>
              <CollapsibleTrigger asChild>
                <SidebarGroupLabel className="font-light opacity-70">
                  {t(group.groupTitle)}
                  <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                </SidebarGroupLabel>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <SidebarMenu>
                  {group.items.map(renderMenuItem)}
                </SidebarMenu>
              </CollapsibleContent>
            </Collapsible>;
      }
      return <SidebarGroupContent key={`group-${index}`}>
            <SidebarMenu>
              {group.map(renderMenuItem)}
            </SidebarMenu>
          </SidebarGroupContent>;
    })}
    </SidebarGroup>;
}
export default SidebarPrimaryNav;