import MobileDrawer from './MobileDrawer';
import Icon from '@/components/ui/Icon';
import { MOBILE_NAV as routes } from '@/data';
import { useTranslations, useActiveRoute } from '@/hooks';
import { Link } from '@/i18n/routing';
import { cn } from '@/utils';
export function MobileNav() {
  const {
    isActive
  } = useActiveRoute();
  const t = useTranslations();
  const renderTabs = routes => {
    return routes.map(link => <li key={link.path} className={cn({
      active: isActive(link.path)
    })}>
          <Link className="flex flex-col items-center justify-center w-full h-full gap-1" href={link?.path}>
            {link.icon && <Icon name={link.icon} type={link?.iconType} />}
            <span>
              {t(link.title)}
            </span>
          </Link>
        </li>);
  };
  const classes = cn({
    'mobile-nav': true
  });
  return <nav className={classes} data-sentry-component="MobileNav" data-sentry-source-file="MobileNav.jsx">
      <ul>
        {renderTabs(routes)}

        <li key="menu">
          <MobileDrawer data-sentry-element="MobileDrawer" data-sentry-source-file="MobileNav.jsx" />
        </li>
      </ul>
    </nav>;
}
export default MobileNav;