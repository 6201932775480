import Icon from '@/components/ui/Icon';
import { TrustpilotMiniWidget } from '@/components/ui/Trustpilot';
import { ROUTES } from '@/data';
const Social = () => {
  return <div className="flex flex-col items-start justify-between col-span-2 md:col-span-1" data-sentry-component="Social" data-sentry-source-file="Social.jsx">
      <ul className="footer-socials">
        <li>
          <a className="text-facebook" href={ROUTES.FACEBOOK} target="_blank" rel="noopener noreferrer">
            <Icon size="lg" name="Facebook" type="brand" data-sentry-element="Icon" data-sentry-source-file="Social.jsx" />
          </a>
        </li>

        <li>
          <a className="text-instagram" href={ROUTES.INSTAGRAM} target="_blank" rel="noopener noreferrer">
            <Icon size="lg" name="Instagram" type="brand" data-sentry-element="Icon" data-sentry-source-file="Social.jsx" />
          </a>
        </li>
      </ul>

      <TrustpilotMiniWidget className="mt-6 md:mt-0 ml-[-1.5rem]" data-sentry-element="TrustpilotMiniWidget" data-sentry-source-file="Social.jsx" />
    </div>;
};
export default Social;