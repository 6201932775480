import { CURRENCY_SYMBOL } from '@/data';
import { cn } from '@/utils';
export function Coins({
  value = 0,
  className
}) {
  const classes = cn({
    'credit-score': true,
    [className]: className
  });
  return <span className={classes} data-sentry-component="Coins" data-sentry-source-file="Coins.jsx">
      <span className="coin-symbol">{CURRENCY_SYMBOL}</span>
      {value}
    </span>;
}
export default Coins;