import { cn } from '@/utils';
export default function AccountSection({
  title,
  children,
  className = ''
}) {
  return <div className={cn('border rounded-lg p-5', className)} data-sentry-component="AccountSection" data-sentry-source-file="AccountSection.jsx">
      {title && <h5>{title}</h5>}
      <div className="space-y-5">
        {children}
      </div>
    </div>;
}