import { useState } from 'react';
import SearchCommand from '@/components/Search/SearchCommand';
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import Icon from '@/components/ui/Icon';
import { ScrollArea } from '@/components/ui/ScrollArea/ScrollArea';
import SidebarPrimaryNav from '@/components/ui/Sidebar/SidebarPrimaryNav';
import { MOBILE_DRAWER as routes } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export function MobileDrawer({
  className
}) {
  const [open, setOpen] = useState(false);
  const classes = cn('mobile-drawer', className);
  const t = useTranslations();
  const handleItemClick = item => {
    setOpen(false);
  };
  return <Drawer className={classes} open={open} onOpenChange={setOpen} data-sentry-element="Drawer" data-sentry-component="MobileDrawer" data-sentry-source-file="MobileDrawer.jsx">
      <DrawerTrigger asChild data-sentry-element="DrawerTrigger" data-sentry-source-file="MobileDrawer.jsx">
        <button className="flex flex-col items-center justify-center w-full h-full gap-1" onClick={() => setOpen(!open)}>
          <Icon name="Menu" data-sentry-element="Icon" data-sentry-source-file="MobileDrawer.jsx" />
          <span>Menu</span>
        </button>
      </DrawerTrigger>
      <DrawerContent className="max-h-[70vh] scroll-container" data-sentry-element="DrawerContent" data-sentry-source-file="MobileDrawer.jsx">
        <ScrollArea className="p-3 max-h-[60vh] overflow-auto" data-sentry-element="ScrollArea" data-sentry-source-file="MobileDrawer.jsx">
          <SearchCommand data-sentry-element="SearchCommand" data-sentry-source-file="MobileDrawer.jsx" />

          <SidebarPrimaryNav items={routes} onItemClick={handleItemClick} data-sentry-element="SidebarPrimaryNav" data-sentry-source-file="MobileDrawer.jsx" />
        </ScrollArea>
      </DrawerContent>
    </Drawer>;
}
export default MobileDrawer;