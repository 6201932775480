'use client';

import Badge from '@/components/ui/Badge';
import { ROUTES } from '@/data';
import { Link } from '@/i18n/routing';
function NavProfileDropdown({
  user
}) {
  return <Link href={ROUTES.PROFILE} className="flex items-center py-2 rounded-md cursor-pointer nav-user hoverable" data-sentry-element="Link" data-sentry-component="NavProfileDropdown" data-sentry-source-file="NavProfileDropdown.jsx">
      <div className="flex flex-col items-start ml-2">
        <span className="text-sm font-medium leading-none truncate">
          {user?.spotifyUsername ?? user?.spotifyId}
        </span>

        {user?.contacts?.email && <span className="mt-1 text-xs text-muted-foreground">
            {user?.contacts?.email}
          </span>}

        {user?.plan?.name && <Badge variant="primary-light" className="mt-1 text-xs capitalize" label={user?.plan?.name} />}
      </div>
    </Link>;
}
export default NavProfileDropdown;