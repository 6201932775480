import { useState } from 'react';
import { BannerNotice } from '@/components/ui/Banner';
import Button from '@/components/ui/Button';
import Emoji from '@/components/ui/Emoji';
import { DEVELOPMENT, ROUTES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { cn } from '@/utils';
function ConfirmEmail({
  className
}) {
  const [isClicked, setIsClicked] = useState(false);
  const t = useTranslations();
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  if (!session || user.verified || isClicked || DEVELOPMENT) {
    return null;
  }
  const classes = cn({
    'py-4': true,
    [className]: className
  });
  return <BannerNotice id="confirm-email" variant="light" size="sm" floating className={classes} data-sentry-element="BannerNotice" data-sentry-component="ConfirmEmail" data-sentry-source-file="ConfirmEmail.jsx">
      <div className="flex flex-col gap-2">
        <p className="m-0 text-sm">
          <Emoji symbol="👋" label="Waving Hand" className="mr-2" data-sentry-element="Emoji" data-sentry-source-file="ConfirmEmail.jsx" />
          <strong>
            {t('common.labels.hi')}{' '}
            {user?.spotifyUsername ?? user?.spotifyId}
            {'! '}
          </strong>
          {t('email_verification.confirm.text')}
        </p>

        <div className="flex flex-col items-center gap-2 md:flex-row">
          <Button small className="flex-1 w-full md:w-auto" variant="outline" onClick={() => setIsClicked(true)} data-sentry-element="Button" data-sentry-source-file="ConfirmEmail.jsx">
            {t('email_verification.confirm.actions.later')}
          </Button>

          <Button small className="flex-1 w-full md:w-auto" variant="inverse" href={ROUTES.PROFILE} onClick={() => setIsClicked(true)} data-sentry-element="Button" data-sentry-source-file="ConfirmEmail.jsx">
            {t('email_verification.confirm.actions.confirm')}
          </Button>
        </div>
      </div>
    </BannerNotice>;
}
export default ConfirmEmail;