import { FOOTER_NAV_1, FOOTER_NAV_2 } from '@/data';
import { useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
const FooterNavigation = () => {
  const t = useTranslations();
  return <>
      <ul key="footer-nav-1" className="footer-menu">
        {FOOTER_NAV_1.map(nav => <li key={nav.path}>
              <Link href={nav.path}>{t(nav.title)}</Link>
            </li>)}
      </ul>
      <ul key="footer-nav-2" className="footer-menu">
        {FOOTER_NAV_2.map(nav => <li key={nav.path}>
              <Link href={nav.path}>{t(nav.title)}</Link>
            </li>)}
        <li key="cookie-preferences">
          <a className="iubenda-cs-preferences-link">{t('consent.cookie_preferences')}</a>
        </li>
      </ul>
    </>;
};
export default FooterNavigation;