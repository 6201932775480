import Copyright from './Copyright';
import FooterNavigation from './FooterNavigation';
import Social from './Social';
import Badge from '@/components/ui/Badge';
import { LogoIconColor } from '@/components/ui/Logo/Logo';
import { useAppStore } from '@/store';
const Footer = () => {
  const {
    version
  } = useAppStore();
  return <footer data-sentry-component="Footer" data-sentry-source-file="Footer.jsx">
      <div className="footer-content">
        <div className="footer-grid">
          <div className="footer-logo">
            <div className="flex flex-col items-start justify-between h-full gap-4">
              <LogoIconColor data-sentry-element="LogoIconColor" data-sentry-source-file="Footer.jsx" />

              {version && <Badge size="sm" variant="light" value={version} className="font-mono" />}
            </div>
          </div>

          <FooterNavigation data-sentry-element="FooterNavigation" data-sentry-source-file="Footer.jsx" />

          <Social data-sentry-element="Social" data-sentry-source-file="Footer.jsx" />
        </div>
      </div>
      <Copyright version={version} data-sentry-element="Copyright" data-sentry-source-file="Footer.jsx" />
    </footer>;
};
export default Footer;