import { useContext } from 'react';
import Button from '@/components/ui/Button';
import { ThemeContext } from '@/context/ThemeProvider';
import { cn } from '@/utils';
const ThemeToggle = ({
  showLabel = false,
  className
}) => {
  const {
    currentTheme,
    applyTheme
  } = useContext(ThemeContext);
  const toggleTheme = e => {
    e.preventDefault();
    if (currentTheme === 'dark') {
      applyTheme('light');
    } else {
      applyTheme('dark');
    }
  };
  const getLabel = () => {
    if (!showLabel) return;
    return currentTheme === 'light' ? 'Dark Mode' : 'Light Mode';
  };
  return <Button icon={currentTheme === 'light' ? 'Moon' : 'Lightbulb'} variant="ghost" onClick={toggleTheme} aria-label="Theme Toggle" className={cn('theme-toggle', className)} label={getLabel()} data-sentry-element="Button" data-sentry-component="ThemeToggle" data-sentry-source-file="ThemeToggle.jsx" />;
};
export default ThemeToggle;