'use client';

import { useEffect } from 'react';
import MobileNav from './MobileNav';
import Nav from './Nav';
import { matchfyApi } from '@/api';
import ConfirmEmail from '@/components/ConfirmEmail';
import Banner, { BannerNotice } from '@/components/ui/Banner';
import { SidebarTrigger, useSidebar } from '@/components/ui/Sidebar/Sidebar';
import { useSession } from '@/hooks';
import { useBannerStore } from '@/store';
import { cn } from '@/utils';
function Header({
  hasSidebar = false
}) {
  const {
    state
  } = useSidebar();
  const {
    data: session
  } = useSession();
  const {
    banner,
    setBanner
  } = useBannerStore();
  useEffect(() => {
    const fetchBanner = async () => {
      const bannerData = await matchfyApi.getBanner();
      if (bannerData && Object.keys(bannerData).length > 0) {
        setBanner(bannerData);
      } else {
        setBanner(null);
      }
    };
    fetchBanner();
  }, []);
  const classes = cn({
    'nav': true,
    [`nav-${state}`]: state,
    'has-sidebar': hasSidebar
  });
  return <>
      <header className={classes}>
        <div className="flex items-center justify-between w-full h-full px-3">
          {hasSidebar && <SidebarTrigger className="hidden lg:block" />}
          <Nav data-sentry-element="Nav" data-sentry-source-file="Header.jsx" />
        </div>
      </header>

      <ConfirmEmail className={cn({
      'mt-10 md:mt-14': banner
    })} data-sentry-element="ConfirmEmail" data-sentry-source-file="Header.jsx" />

      <Banner data-sentry-element="Banner" data-sentry-source-file="Header.jsx" />

      <BannerNotice id="dynamic-banner" variant="gradient" banner={banner} data-sentry-element="BannerNotice" data-sentry-source-file="Header.jsx" />

      {session ? <MobileNav /> : null}
    </>;
}
export default Header;