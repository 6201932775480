import React from 'react';
import Icon from '@/components/ui/Icon';
import { NavigationMenuLink } from "@/components/ui/navigation-menu";
import { useActiveRoute } from '@/hooks';
import { Link } from '@/i18n/routing';
import { cn } from '@/utils';
const NavListItem = React.forwardRef(({
  className,
  title,
  subtitle,
  children,
  icon,
  color,
  iconType = 'lucide',
  ...props
}, ref) => {
  const {
    isActive
  } = useActiveRoute();
  const classes = cn({
    'nav-list-item': true,
    'hoverable': true,
    'h-full': true,
    active: isActive(props.href)
  });
  return <li>
      <NavigationMenuLink asChild>
        <Link ref={ref} className={classes} {...props}>
          {icon && <Icon name={icon} className={cn({
          'icon-md w-14 h-14 p-4 rounded': true,
          'icon-primary': !color,
          [`icon-${color}`]: color
        })} type={iconType} />}

          <div className="flex flex-col items-start gap-1">
            <div className="text-base font-medium leading-none">
              {title}
            </div>

            {subtitle && <p className="m-0 text-sm leading-snug text-light opacity-70 line-clamp-2 text-balance">
                {subtitle}
              </p>}
          </div>
        </Link>
      </NavigationMenuLink>
    </li>;
});
export default NavListItem;